import 'es6-promise/auto'
import 'isomorphic-fetch'
import Vue from 'vue'
import App from '@/App'
import router from '@/router'
import '@/plugins/axios'
import '@/plugins/bootstrap-vue'
import '@/sass/app.scss'

if (process.env.VUE_APP_APP_ENV === 'production') {
	try {
		require('@/plugins/analytics')
	} catch (e) {
		console.log('Our analytics tracker failed to load!')
		let a = document.getElementById('erroranalytics')
		a.style.textAlign = 'center'
		a.style.width = '100%'
		a.style.backgroundColor = '#d71920'
		a.style.display = 'inline-block'
	}
	try {
		require('@/plugins/captcha')
	} catch (e) {
		console.log('Our analytics tracker failed to load!')
		let a = document.getElementById('errorcaptcha')
		a.style.textAlign = 'center'
		a.style.width = '100%'
		a.style.backgroundColor = '#d71920'
		a.style.display = 'inline-block'
	}
}

Vue.config.productionTip = false

/* eslint-disable no-new */
new Vue({
	el: '#app',
	router,
	template: '<App/>',
	components: { App },
	data: () => ({
		stateProvinceOptions: []
	}),
	mounted () {
		this.axios({
			method: 'get',
			url: '/state-provinces'
		}).then(response => {
			this.stateProvinceOptions = response.data
		});
	},
	methods: {
		setTitle (title) {
			document.title = title + ' | US - India Partnership 2020'
		}
	}
});
