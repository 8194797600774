<template>
	<footer>
		<b-container id="my-footer">
		<b-row align-h="center">
			<b-col cols="12" md="8">
				<b-row id="navItems" class="mr-md-1">
					<b-nav id="navCol" class="ml-md-auto">
						<b-nav-item
							:active="(this.$route.name === 'Home')"
							class="navLink"
							href="/">
							Home
						</b-nav-item>
						<b-nav-item
							:active="(this.$route.name === 'Partnerships')"
							class="navLink"
							href="/partnerships">
							Partnerships
						</b-nav-item>
						<b-nav-item
							:active="(this.$route.name === 'About')"
							class="navLink"
							href="/about">
							About
						</b-nav-item>
						<b-nav-item
							:active="(this.$route.name === 'Contact')"
							class="navLink"
							href="/contact">
							Contact
						</b-nav-item>
					</b-nav>
				</b-row>
				<b-row id="searchItem" align-h="center">
					<b-col cols="12">
						<search-input
							class="search"
							placeholder="Search Partnerships">
						</search-input>
					</b-col>
				</b-row>
				<b-row id="logos" align-h="center">
					<b-col cols="4" class="mt-auto mb-auto">
						<a href="https://www.csis.org/" target="_blank" rel="noopener noreferrer">
							<img id="csisLogo" class="mx-auto d-block" alt="CSIS Logo" src="../../static/img/CSISlogo.png">
						</a>
					</b-col>
					<b-col cols="4" class="mt-auto mb-auto">
						<a href="https://www.state.gov/" target="_blank" rel="noopener noreferrer">
							<img id="stateLogo" class="mx-auto d-block" alt="State Department Logo" src="../../static/img/StateDeptLogo-Sm.png">
						</a>
					</b-col>
					<b-col cols="4" class="mt-auto mb-auto">
						<a href="https://www.unomaha.edu/international-studies-and-programs/engagement/partnership2020/index.php" target="_blank" rel="noopener noreferrer">
							<img  id="unoLogo" class="mx-auto d-block" alt="UNO logo" src="../../static/img/unoLogo.png">
						</a>
					</b-col>
				</b-row>
			</b-col>
			</b-row>
			<b-row class="mb-2 message" align-h="center">
				<b-col id="discT" cols="12" md="6">
					<p class="text">Website created by staff and students at the
					<a class="text" href="https://cist.unomaha.edu" target="_blank" rel="noopener noreferrer">College of Information Science and Technology.</a>
					</p>
				</b-col>
			</b-row>
			<b-row class="mb-2 message" align-h="center">
				<b-col id="discT" cols="12" md="6">
					<p class="text">The University of Nebraska does not discriminate based on race, color, ethnicity, national origin, sex, pregnancy, sexual orientation, gender indentity, religion, disability, age, genetic information, veteran status, marital status, and/or political affiliation in its programs, activities, or employment. Learn more about Equity, Access, and Diversity.</p>
					<div id="reportIssue">
						<p class="text" v-html="footerCopyright"></p>
					</div>
				</b-col>
			</b-row>
		</b-container>
	</footer>
</template>

<script>
import SearchInput from './SearchInput'

export default {
	name: 'MyFooter',
	data: () => ({
		search: ''
	}),
	components: {
		SearchInput
	},
	computed: {
		footerCopyright () {
			return '&#169; ' + (new Date().getFullYear())
		}
	},
	methods: {
		submitSearch () {
			this.$router.push({
				name: 'Partnerships',
				query: {
					search: this.search,
					order: this.$route.query.order,
					country: this.$route.query.country,
					state_province: this.$route.query.state_province
				}
			})

			//this.$router.go() // this causes an extra, unneeded page refresh. may be here for a reason
		}
	}
}
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 767px) {
		footer{
		background-image: url("../../static/img/footerBackgroundImageSmall.png");
		}
	}

	@media screen and (min-width: 768px) and (max-width: 2000px) {
		footer {
		background-image: url("../../static/img/footerBackgroundImageMedium.png");
		}
	}

	@media screen and (min-width: 2000px) and (max-width: 4000px) {
		footer {
		background-image: url("../../static/img/footerBackgroundImageBig.png");
		}
	}

	footer {
		margin-top: 1%;
		width: 100%;
		background-repeat: no-repeat;
		border-top: 0.25rem solid #d71920;
		padding-top: 1%;
		padding-left: 2%;
		padding-right: 2%;
		padding-bottom: 2%;
	}

	#searchTitle, #partnershipTitle {
		text-decoration: underline;
		font-size: 90%;
	}

	#my-footer {
		width: 100%;
		padding-left: 4%;
		padding-right: 4%;
	}

	.text {
		display: inline;
		font-size: 90%;
		line-height: 90%;
	}

	@media screen and (min-width: 768px) {
		.search {
		width: 60%;
		}
	}

	@media screen and (max-width: 767px) {
		#searchContainer {
		text-align: center;
		}

		#partnershipAbout {
		text-align: center;
		}

		#navCol {
		margin-left: auto;
		margin-right: auto;
		}
	}

	#navCol {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 1%;
	}

	#csisLogo, #stateLogo, #unoLogo {
		height: auto;
		display: inline-block;
	}

	#csisLogo {
		width: 100%;
	}

	#stateLogo {
		width: 50%
	}

	#unoLogo {
		width: 100%;
	}

	#logos {
		text-align: center;
		padding-top: 1rem;
		padding-bottom: 1rem;
		align-items: center; // solves IE margin within flexBox bug
	}

	.logos {
		height: auto;
	}

	.message {
		line-height: 90%;
	}

	a {
		color: #000000;
		font-size: 1.15rem;
	}

	a:active,
	a:focus,
	a:hover,
	a:visited {
		border-bottom: 0 !important;
		color: #000000;
		font-size: 1.15rem;
	}

	a.active {
		border-bottom: 0.15rem solid #d71920 !important;
		color: #000000;
		font-size: 1.15rem;
	}

	a.active:active,
	a.active:focus,
	a.active:hover,
	a.active:visited {
		border-bottom: 0.15rem solid #d71920 !important;
		color: #000000;
		font-size: 1.15rem;
	}

	a.text {
		font-size: 0.85rem;
	}

	a.text:active,
	a.text:focus,
	a.text:hover,
	a.text:visited {
		border-bottom: 0 !important;
		color: #000000;
		font-size: 0.85rem;
	}

	a.text.active:active,
	a.text.active:focus,
	a.text.active:hover,
	a.text.active:visited {
		border-bottom: 0.15rem solid #d71920 !important;
		color: #000000;
		font-size: 0.85rem;
	}


	#discT {
		text-align: center;
	}
</style>
