<template>
	<div id="app">
		<my-navbar></my-navbar>
		<div id="content">
			<b-container fluid>
				<router-view :key="$route.name"/>
			</b-container>
		</div>
		<my-footer></my-footer>
	</div>
</template>

<script>
import MyFooter from '@/components/MyFooter'
import MyNavbar from '@/components/MyNavbar'

export default {
	name: 'App',
	components: {
		MyFooter,
		MyNavbar
	}
}
</script>

<style lang="scss">
	#content {
		height: 110%;
		padding-bottom: 2%;
	}

	.container-fluid {
		padding: 0 !important;
		margin: 0 !important;
	}
</style>
