<template>
<div id="my-navbar">
  <b-navbar toggleable="xl" type="dark" variant="dark">
    <b-navbar-brand href="/">
      <div class="subsite-logos">
        <a href="/"><img alt="University of Nebraska Omaha" src="../../static/img/o.png"></a>
        <div id="div">
          <a class="college wrap" href="/">University of Nebraska Omaha</a>
          <a class="department wrap" href="/">US - India Partnership 2020</a>
        </div>
      </div>
    </b-navbar-brand>
    <b-navbar-toggle target="collapse"></b-navbar-toggle>
    <b-collapse is-nav id="collapse">
      <b-navbar-nav class="ml-auto pt-2">
        <b-nav-item
          :active="(this.$route.name === 'Home')"
          class="top-nav"
          id="home-nav"
          href="/">
          Home
        </b-nav-item>
        <b-nav-item
          :active="(this.$route.name === 'Partnerships')"
          class="bottom-nav"
          id="partnerships-nav"
          href="/partnerships">
          Partnerships
        </b-nav-item>
        <b-nav-item
          :active="(this.$route.name === 'About')"
          class="top-nav"
          id="about-nav"
          href="/about">
          About
        </b-nav-item>
        <b-nav-item
          :active="(this.$route.name === 'Contact')"
          class="top-nav"
          id="contact-nav"
          href="/contact">
          Contact
        </b-nav-item>
      </b-navbar-nav>
      <search-input
        placeholder="Search Partnerships">
      </search-input>
    </b-collapse>
  </b-navbar>
</div>
</template>

<script>
import SearchInput from '@/components/SearchInput'

export default {
  name: 'MyNavbar',
  data: () => ({
    search: ''
  }),
  components: {
    SearchInput
  }
}
</script>

<style lang="scss" scoped>
  #my-navbar {
    white-space: nowrap;
  }

  .navbar-brand {
    font-size: 1.15rem;
  }

  .subsite-logos {
    display: table;
    height: 100%;
    vertical-align: middle;
    width: 100%;
    border-collapse: collapse;
  }

  .subsite-logos a:link, .subsite-logos a:visited, .subsite-logos a:active {
    color: #FFF;
    text-decoration: none;
  }

  .wrap {
    white-space: normal;
  }

  .subsite-logos img {
    display: inline;
    margin-right: 10px;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    border: 0;
  }

  .subsite-logos #div {
    display: inline-block;
    max-width: 290px;
    vertical-align: middle;
    border-collapse: collapse;
    height: 100%;
  }

  .subsite-logos .college {
    float: left;
    margin: 8px 0 0 2px;
    display: block;
    font-family: 'urwgroteskmedregular';
    text-transform: uppercase;
    font-size: .78em;
    width: 100%;
  }

  .subsite-logos .department {
    float: left;
    margin: 5px 0 0 1px;
    display: block;
    font-family: 'urwgroteskligregular';
    text-transform: uppercase;
    font-size: 1.3em;
    letter-spacing: -1.5px;
    word-spacing: 2px;
    width: 100%;
    line-height: .9;
  }

  .bg-dark {
    background-color: #000000 !important;
  }

  .nav-item {
    margin-right: 1rem;
  }

  .nav-link:hover, .active {
    padding-bottom: 0.35rem !important;
    border-bottom: 0.15rem solid #d71920 !important;
  }

  a:hover, a:active {
    font-size: 1.15rem;
  }

  @media (min-width: 466px) {
    #div {
      width: auto;
    }
    .college {
      line-height: .3em;
    }
  }

  @media (min-width: 421px) and (max-width: 465px) {
    #div {
      width: 200px;
    }
  }

  @media (max-width: 420px) {
    #div {
      width: 150px;
    }
  }

  @media (max-width: 340px) {
    #div {
      width: 120px;
    }

    .college {
      line-height: 1rem;
    }
  }

  @media (max-width: 295px) {
    .navbar-toggler {
      width: 100%;
      margin-top: 4%;
    }
  }
</style>
