import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Home',
			component: () => import('@/pages/Home')
		},
		{
			path: '/contact',
			name: 'Contact',
			component: () => import('@/pages/Contact')
		},
		{
			path: '/about',
			name: 'About',
			component: () => import('@/pages/About')
		},
		{
			path: '/map-results/:state_province_slug',
			name: 'MapResults',
			component: () => import('@/pages/MapResults')
		},
		{
			path: '/partnerships',
			name: 'Partnerships',
			component: () => import('@/pages/Partnerships')
		},
		{
			path: '/partnership/:partnership_slug',
			name: 'Partnership',
			component: () => import('@/pages/Partnership')
		},
		{
			path: '/request-listing',
			name: 'RequestListing',
			component: () => import('@/pages/RequestListing')
		},
		{
			path: '*',
			name: 'FOFError',
			component: () => import('@/pages/FOFError')
		}
	],
	scrollBehavior (to, from, savedPosition) { // controls navigation behavior
		if (savedPosition) {
			return savedPosition // if savedPosition is filled, then navigate there
		} else if (to.hash) {
			return { selector: to.hash } // simulated scroll-to-anchor behavior
		} else {
			return {x: 0, y: 0} // else scroll to top
		}
	}
})
