<template>
	<div id="search-input">
		<div class="form-control search">
			<input
				name="search"
				:placeholder="placeholder"
				type="text"
				v-model="search"
				@keyup.enter="submitSearch">
			<button
				:class="(search === '') ? 'hidden' : ''"
				type="button"
				@click="clearSearch">
				<span>X</span>
			</button>
		</div>
	</div>
</template>

<script>
export default {
	data: () => ({
		search: ''
	}),
	methods: {
		clearSearch () {
		this.search = ''
		},
		submitSearch (event) {
		if (this.search === '') {
			event.preventDefault()
		} else {
			this.$router.push({
				name: 'Partnerships',
				query: {
					search: this.search,
					order: this.$route.query.order,
					country: this.$route.query.country,
					state_province: this.$route.query.state_province
				}
			})

			//this.$router.go() // this causes an extra, unneeded page refresh. may be here for a reason
		}
		}
	},
	props: {
		placeholder: {
		default: '',
		required: false,
		type: String
		},
		value: {
		default: '',
		required: false,
		type: String
		}
	},
	watch: {
		$route (/*to, from*/) {
		this.search = ''
		}
	}
}
</script>

<style lang="scss" scoped>
	.form-control {
		border: 0;
		border-radius: 0.35rem;
		height: 2.5rem;
	}

	.search {
		display: flex;
		width: 12rem;
	}

	@media screen and (max-width: 1080px) {
		#search-input {
		padding-top: 1rem;
		padding-bottom: 1rem;
		width: 100%;
		}
		.search {
		width:100%;
		}
	}

	#my-footer .search {
		width: 100%;
	}

	.search > input[type="text"] {
		background-color: transparent;
		border: 0;
		color: #ffffff;
		font-size: 0.85rem;
		height: 100%;
		width: 100%;
	}

	.search > input[type="text"]:active,
	.search > input[type="text"]:focus {
		box-shadow: none;
		outline: none;
	}

	.search > input[type="text"]:focus::placeholder {
		color: transparent;
	}

	.search > input[type="text"]:focus:ms-placeholder {
		color: transparent;
	}

	.search > input[type="text"]:focus::ms-placeholder {
		color: transparent;
	}

	.search > input[type="text"]::placeholder {
		opacity: 0.85;
		color: rgb(188, 187, 186);
		font-size: 0.85rem;
	}

	.search > input[type="text"]:-ms-input-placeholder {
		color: rgb(188, 187, 186);
		font-size: 0.85rem;
	}

	.search > input[type="text"]::-ms-input-placeholder {
		color: rgb(188, 187, 186);
		font-size: 0.85rem;
	}

	.search > input::-ms-clear {
		display: none;
	}

	.search > button {
		background-color: transparent;
		border: 0;
		margin-left: 2%;
		padding-bottom: 0;
		height: 100%;
		cursor: pointer;
	}

	.search > button.hidden {
		visibility: hidden;
	}

	.search > button > span {
		position: relative;
		top: 0;
		display: inline-block;
		color: rgb(188, 187, 186);
		font-size: 1.10rem;
	}

	.search > button:active,
	.search > button:focus,
	.search > button:hover {
		border: none;
		outline: none;
	}
</style>
